require('styles/normalize.css')
const { themeClass } = require('styles/theme.css.ts')

function attachGlobalTheme() {
    document.body.classList.add(themeClass)
}

exports.onClientEntry = () => {
    console.log("We've started!")
    attachGlobalTheme()
    // callAnalyticsAPI()
}

exports.onInitialClientRender = () => {
    console.log('ReactDOM.render has executed')
}
